/* We utilize the html5 summary tags in order to create a post archive */
/* with built-in folding. */
details {
    border-radius: 3px;
}

details summary {
    vertical-align: top;
    padding: .3em .5em;
    outline: none;
    /*color: rgba(65, 70, 75, 1);*/
}

details summary.year {
    font-size: 1.5rem;
}

// details[open] summary {
// }


/* Table of Contents */
.toc summary {
    font-size: 1.5rem;
    margin-bottom: -1.5rem;
    padding-left: 0;
}

.toc {
	// float: right;
	/*padding: 0rem 1rem 1rem 1rem;*/
    /*margin-top: 1rem;*/
	// border-left: 1px solid #eee;
}

.toc ul {
	list-style: none;
	display: block;
	/*margin-top: 0.75rem;*/
	padding: 0;
    width: 87.5%;
}

.toc li {
    line-height: 0.5rem;
    margin: 1rem;
}
